import {
  FsDescription,
  FsHeading,
  HeadingSize,
} from '@printi/ds-offset-react-core';
import * as S from './Heading.styles';

export enum HeadingType {
  HeadingDescription = 'hd',
  DescriptionHeading = 'dh',
  HeadingDescription2Xs = 'hd2xs',
  HeadingHeadingDescription = 'hhd',
  HeadingDescriptionButton = 'hdb',
}

interface HeadingProps {
  heading?: string;
  heading2XS?: string;
  description?: string;
  type?: HeadingType;
  buttonMini?: React.ReactNode;
}

export const Heading = ({
  heading = 'Heading',
  heading2XS = '',
  description = 'description',
  type = HeadingType.HeadingDescription,
  buttonMini,
}: HeadingProps) => {
  return (
    <S.Container type={type}>
      {type === 'hd' && (
        <>
          <FsHeading size={HeadingSize.MD}>{heading}</FsHeading>
          <FsDescription>{description}</FsDescription>
        </>
      )}
      {type === 'hd2xs' && (
        <>
          <FsHeading size={HeadingSize['2XS']}>{description}</FsHeading>
          <FsHeading size={HeadingSize.MD}>{heading}</FsHeading>
        </>
      )}
      {type === 'dh' && (
        <>
          <FsDescription>{description}</FsDescription>
          <FsHeading size={HeadingSize.MD}>{heading}</FsHeading>
        </>
      )}
      {type === 'hhd' && (
        <>
          <FsHeading size={HeadingSize['2XS']}>{heading2XS}</FsHeading>
          <div>
            <FsHeading size={HeadingSize.MD}>{heading}</FsHeading>
            <FsDescription>{description}</FsDescription>
          </div>
        </>
      )}
      {type === 'hdb' && (
        <>
          <FsHeading size={HeadingSize.MD}>{heading}</FsHeading>
          <S.DescriptionContent>
            <FsDescription>{description}</FsDescription>
            {buttonMini}
          </S.DescriptionContent>
        </>
      )}
    </S.Container>
  );
};
