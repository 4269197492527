export interface AuthContextProps {
  baseUrl: string;
  setBaseUrl: React.Dispatch<React.SetStateAction<string>>;

  step: LoginSteps;
  setStep: React.Dispatch<React.SetStateAction<LoginSteps>>;

  progress: number;
  setProgress: React.Dispatch<React.SetStateAction<number>>;

  registerData: TRegisterData;
  setRegisterData: React.Dispatch<React.SetStateAction<TRegisterData>>;

  image: string;
  setImage: React.Dispatch<React.SetStateAction<string>>;

  resendEmail: string;
  setResendEmail: React.Dispatch<React.SetStateAction<string>>;

  toast: string;
  setToast: React.Dispatch<React.SetStateAction<string>>;

  toastType: string;
  setToastType: React.Dispatch<React.SetStateAction<string>>;

  toastOpen: boolean;
  setToastOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum LoginSteps {
  SignIn = 0,
  RecoverAccess = 1,
  Register = 2,
  AccessEmail = 3,
  RegisterName = 4,
  TypeAccount = 5,
  PhysicalPerson = 6,
  LegalPerson = 7,
  CreatePassword = 8,
  ResendMail = 9,
}

export interface AuthContextProviderProps {
  children: React.ReactNode;
}

export type TPhysicalPerson = {
  cpf: string;
  phone: string;
  birthdate: string;
  occupation?: string;
};

export type TRegisterData = {
  email: string;
  password: string;
  name: string;
  typeAccount: 'PF' | 'PJ';
  receiveOffers: boolean;
  phone: string;

  cnpj?: string;
  corporateReason?: string;
  numberOfEmployees?: string;
  stateRegistration?: string;
  stateRegistrationId?: string;

  cpf?: string;
  birthdate?: string;
};
