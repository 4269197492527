// eslint-disable-next-line
import styled, { css } from 'styled-components';
import { HeadingType } from './Heading';

interface ContainerProps {
  type?: HeadingType;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;

  gap: var(--spacing-2x);
  padding: var(--spacing-none);

  ${({ type }) =>
    type === 'hhd' &&
    css`
      > div {
        display: flex;
        gap: var(--spacing-2x);
        flex-direction: column;
      }
    `}
`;

export const DescriptionContent = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: var(--spacing-2x) var(--spacing-1x);
  align-self: stretch;
  flex-wrap: wrap;
`;
