'use client';

import {
  FsRequirementList,
  FsRequirementListGroup,
} from '@printi/ds-offset-react-core';
import { useCreatePasswordHooks } from '../CreatePassword/CreatePassword.hooks';

interface RequirementPasswordProps {
  password: string;
}

export const RequirementPassword = ({ password }: RequirementPasswordProps) => {
  const { validatePassword } = useCreatePasswordHooks();

  return (
    <FsRequirementListGroup>
      <FsRequirementList
        label="Mínimo 7 letras"
        negative={!validatePassword(password).minLength}
      />
      <FsRequirementList
        label="1 maiúscula"
        negative={!validatePassword(password).upperCase}
      />
      <FsRequirementList
        label="1 número"
        negative={!validatePassword(password).number}
      />
      <FsRequirementList
        label="1 caractere especial como !@#$%"
        negative={!validatePassword(password).specialChar}
      />
    </FsRequirementListGroup>
  );
};
