import { api } from '@mf/common/config/axios/serviceApi';
import { TAxiosDefaultConfig } from '@mf/common/repositories/aecomStrapiCms/defaults/types';
import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { signOut } from 'next-auth/react';
import {
  TCustomerData,
  TCustomerDataCompanyForm,
  TCustomerDataPersonForm,
  TGetCustomerData,
} from './types';

const bffCustomerData = ({ baseUrl, token }: TAxiosDefaultConfig) => {
  const apiBff: AxiosInstance = api({ baseUrl, token });

  const editCustomerData = (
    data: TCustomerDataPersonForm | TCustomerDataCompanyForm,
  ) => {
    const response = apiBff.put('/v1/my-account', data);

    return response;
  };

  //tratar exceçoes - 401 não autorizado
  const getCustomerData = async (): Promise<TCustomerData | null> => {
    try {
      const { data }: TGetCustomerData = await apiBff.get(`/v1/my-account`);
      return data;
    } catch {
      signOut();
      return null;
    }
  };

  const useCustomerdata = () => {
    return useQuery({
      queryKey: ['customer-data'],
      queryFn: () => getCustomerData(),
      enabled: !!token,
    });
  };

  return {
    editCustomerData,
    getCustomerData,
    useCustomerdata,
  };
};

export default bffCustomerData;
