'use client';

import { createContext, useContext, useState } from 'react';
import { ToastType } from '@printi/ds-offset-react-core';
import {
  AuthContextProps,
  AuthContextProviderProps,
  LoginSteps,
  TRegisterData,
} from './auth.types';

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [baseUrl, setBaseUrl] = useState<string>('');

  const [step, setStep] = useState<LoginSteps>(LoginSteps.SignIn);
  const [toast, setToast] = useState<string>('');
  const [toastType, setToastType] = useState<string>(ToastType.Negative);
  const [toastOpen, setToastOpen] = useState<boolean>(false);

  const [progress, setProgress] = useState<number>(0);

  const [image, setImage] = useState<string>(
    'https://d1br4h274rc9sc.cloudfront.net/content/Login1_f469fbcb10.png',
  );

  const [registerData, setRegisterData] = useState<TRegisterData>({
    email: '',
    password: '',
    name: '',
    typeAccount: 'PF',
    receiveOffers: false,
    phone: '',
  });

  const [resendEmail, setResendEmail] = useState<string>('');

  return (
    <AuthContext.Provider
      value={{
        baseUrl,
        setBaseUrl,
        step,
        setStep,
        progress,
        setProgress,
        registerData,
        setRegisterData,
        image,
        setImage,
        resendEmail,
        setResendEmail,
        toast,
        setToast,
        toastType,
        setToastType,
        toastOpen,
        setToastOpen,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthContextProvider');
  }

  return context;
};
