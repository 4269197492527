'use client';

import { useAuth } from '@mf/common/contexts/auth/auth';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCallback } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useSearchParams } from 'next/navigation';
import { signIn } from 'next-auth/react';
import { LoginSteps } from '@mf/common/contexts/auth/auth.types';
import { ToastType } from '@printi/ds-offset-react-core';
import bffAuth from '../../repositories/bff/auth/index';
import { TRegisterPayload } from '../../repositories/bff/auth/types';

export const useCreatePasswordHooks = () => {
  const {
    baseUrl,
    registerData,
    setRegisterData,
    setStep,
    setToast,
    setToastOpen,
    setToastType,
  } = useAuth();
  const { register } = bffAuth({ baseUrl });
  const searchParams = useSearchParams();

  const { mutate, isLoading, isSuccess } = useMutation(register, {
    onSuccess: () => {
      const fpPromise = FingerprintJS.load();

      fpPromise
        .then((fp) => fp.get())
        .then(async (result) => {
          const fingerprint = result.visitorId;

          await signIn('credentials', {
            email: registerData.email,
            password: registerData.password,
            fingerprint: fingerprint,
            redirect: true,
            callbackUrl: searchParams.get('callbackUrl') || '/',
          });
        });
    },
    onError: () => {
      setStep(LoginSteps.RecoverAccess);
      setToast(
        'Você já possui uma conta. Caso tenha esquecido sua senha, recupere o acesso.',
      );
      setToastType(ToastType.Negative);
      setToastOpen(true);
    },
  });

  const registerUser = useCallback(
    (payload: TRegisterPayload) => {
      mutate({ payload: payload });
    },
    [mutate],
  );

  const validatePassword = (password: string) => {
    const validations = {
      minLength: password.length >= 7,
      upperCase: /[A-Z]/.test(password),
      number: /[0-9]/.test(password),
      specialChar: /[\W_]/.test(password),
    };

    return validations;
  };

  const schema = Yup.object().shape({
    password: Yup.string()
      .min(7, 'A senha deve ter no mínimo 7 caracteres')
      .matches(/[A-Z]/, 'A senha deve conter pelo menos 1 letra maiúscula')
      .matches(/[0-9]/, 'A senha deve conter pelo menos 1 número')
      .matches(/[\W_]/, 'A senha deve conter pelo menos 1 caractere especial')
      .required('Senha é obrigatória'),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('password')],
        'Suas senhas não coincidem. Por favor, tente novamente.',
      )
      .required('Confirmação de senha é obrigatória'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      try {
        setRegisterData((state) => ({
          ...state,
          password: values.password,
        }));

        const fpPromise = FingerprintJS.load();

        fpPromise
          .then((fp) => fp.get())
          .then((result) => {
            const fingerprint = result.visitorId;
            const formattedBirthdate = registerData?.birthdate
              ?.split('/')
              .reverse()
              .join('-');

            registerUser({
              name: registerData.name,
              terms_of_use: true,
              privacy_police: true,
              exclusive_offers_subscribe: registerData.receiveOffers,
              email: registerData.email,
              email_confirmation: registerData.email,
              password: values.password,
              fingerprint: JSON.stringify({ fingerprint }),
              type: registerData.typeAccount,

              phone: registerData.phone,

              ...(registerData.typeAccount === 'PF' && {
                cpf: registerData.cpf,
                birthdate: formattedBirthdate,
              }),

              ...(registerData.typeAccount === 'PJ' && {
                cnpj: registerData.cnpj,
                trading_name: registerData.corporateReason,
                state_registration: registerData.stateRegistration,
                id_state_registration: registerData.stateRegistrationId,
                employee_number: registerData.numberOfEmployees,
              }),
            });
          });
      } catch {
        setStep(LoginSteps.RegisterName);
      }
    },
  });

  return {
    formik,
    validatePassword,
    isLoading,
    isSuccess,
  };
};
