import { CustomSession } from '@mf/common/config/nextAuth/types';
import { useAuth } from '@mf/common/contexts/auth/auth';
import { LoginSteps } from '@mf/common/contexts/auth/auth.types';
import { signOut, useSession } from 'next-auth/react';
import { useCallback } from 'react';

const useFormContainerHooks = () => {
  const { step, registerData } = useAuth();
  const { data: session } = useSession();
  const customSession = session as CustomSession;

  const isSocialRegister = () => {
    return !!customSession?.profile?.id;
  };

  const showBackButton = [
    LoginSteps.RecoverAccess,
    LoginSteps.RegisterName,
    LoginSteps.TypeAccount,
    LoginSteps.LegalPerson,
    LoginSteps.PhysicalPerson,
    LoginSteps.CreatePassword,
    LoginSteps.ResendMail,
  ].includes(step);

  const showTerms = [
    LoginSteps.LegalPerson,
    LoginSteps.PhysicalPerson,
  ].includes(step);

  const getBackStep = useCallback(() => {
    if (isSocialRegister()) {
      signOut();
      return LoginSteps.SignIn;
    }

    if ([LoginSteps.RecoverAccess].includes(step)) {
      return LoginSteps.SignIn;
    }

    if (step === LoginSteps.RegisterName) {
      return LoginSteps.Register;
    }

    if (step === LoginSteps.TypeAccount) {
      return LoginSteps.RegisterName;
    }

    if (step === LoginSteps.CreatePassword) {
      return registerData.typeAccount === 'PF'
        ? LoginSteps.PhysicalPerson
        : LoginSteps.LegalPerson;
    }

    if ([LoginSteps.PhysicalPerson, LoginSteps.LegalPerson].includes(step)) {
      return LoginSteps.TypeAccount;
    }

    return LoginSteps.SignIn;
    // eslint-disable-next-line
  }, [step]);

  return {
    getBackStep,
    showBackButton,
    showTerms,
  };
};

export default useFormContainerHooks;
