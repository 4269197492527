import { api } from '@mf/common/config/axios/serviceApi';
import { TAxiosDefaultConfig } from '@mf/common/repositories/aecomStrapiCms/defaults/types';
import { AxiosInstance } from 'axios';
import {
  ForgotPasswordProps,
  JwtProps,
  RegisterProps,
  ResetPasswordProps,
  TForgotPasswordResponse,
  TJwtResponse,
  TRegisterResponse,
  TValidateHashResetPassword,
} from './types';

const bffAuth = ({ baseUrl, token }: TAxiosDefaultConfig) => {
  const apiBff: AxiosInstance = api({ baseUrl, token });

  const register = async ({ payload }: RegisterProps) => {
    const { data } = await apiBff.post<TRegisterResponse>(
      '/v1/auth/register',
      payload,
    );

    return data;
  };

  const jwt = async ({ payload }: JwtProps) => {
    const { data } = await apiBff.post<TJwtResponse>('/v1/auth/login', payload);

    return data;
  };

  const forgotPassword = async ({ payload }: ForgotPasswordProps) => {
    const { data } = await apiBff.post<TForgotPasswordResponse>(
      '/v1/auth/forgot-password',
      payload,
    );

    return data;
  };

  const validateHashResetPassword = async (hash: string) => {
    const { data } = await apiBff.get<TValidateHashResetPassword>(
      `/v1/auth/reset-password/${hash}`,
    );
    return data;
  };

  const resetPassword = async (
    hash: string,
    { payload }: ResetPasswordProps,
  ) => {
    const { data } = await apiBff.post(
      `/v1/auth/reset-password/${hash}`,
      payload,
    );

    return data;
  };

  return {
    register,
    jwt,
    forgotPassword,
    validateHashResetPassword,
    resetPassword,
  };
};

export default bffAuth;
