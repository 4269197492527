// eslint-disable-next-line
import { media } from '@mf/common/utils/styles/index';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  min-height: 100vh;

  transition: 0.5s;

  width: 100%;
`;

export const Header = styled.header`
  height: var(--size-12x);
  padding: 0px var(--spacing-5x);

  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  ${media.lessThan('mobile')} {
    height: var(--size-10x);
    padding: 0px var(--spacing-2x);
  }
`;

export const ContentBrand = styled.div`
  height: 28px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;

  ${media.lessThan('mobile')} {
    height: 24px;
    padding: 0px;

    > div {
      height: 100%;
    }
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  gap: var(--spacing-3x);
  padding: var(--spacing-6x) var(--spacing-5x);

  ${media.lessThan('mobile')} {
    padding: var(--spacing-4x) var(--spacing-2x);
  }
`;

export const ContainerForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
`;

export const Content = styled.div`
  width: 480px;
  max-width: 480px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    gap: var(--spacing-3x);
    padding: var(--spacing-none);
  }
`;

export const Terms = styled.div`
  display: flex;
  width: 480px;
  max-width: 480px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-3x);

  ${media.lessThan('mobile')} {
    width: auto;
    max-width: unset;
    align-self: stretch;
  }
`;

export const Support = styled.div`
  display: flex;
  max-width: 480px;
  align-items: center;
  align-content: center;
  align-self: stretch;
  flex-wrap: wrap;

  gap: var(--spacing-half) var(--spacing-1x);
`;
