'use client';

import {
  BrandType,
  FsBrand,
  FsButtonMini,
  FsLink,
  FsParagraph,
  FsProgressLine,
} from '@printi/ds-offset-react-core';
import { useRouter } from 'next/navigation';
import { useAuth } from '@mf/common/contexts/auth/auth';
import { CustomSession } from '@mf/common/config/nextAuth/types';
import { useSession } from 'next-auth/react';
import bffCustomerData from '@mf/auth/repositories/bff/customer-data';
import * as S from './FormContainer.styles';
import useFormContainerHooks from './FormContainer.hooks';

export const FormContainer = ({ children }: { children: React.ReactNode }) => {
  const { push } = useRouter();
  const { setStep, progress } = useAuth();
  const { getBackStep, showBackButton, showTerms } = useFormContainerHooks();

  const { data: session } = useSession();
  const customSession = session as CustomSession;

  const { useCustomerdata } = bffCustomerData({
    baseUrl: process.env.BFF_API_BASE_URL,
    token: customSession?.user?.token,
  });
  const { data: customerData } = useCustomerdata();

  return (
    <S.Container>
      <S.Header>
        <S.ContentBrand>
          <FsBrand
            type={BrandType.Logotype}
            style={{
              width: '94px',
              height: '24px',
            }}
          />
        </S.ContentBrand>
        {showBackButton ? (
          <div>
            <FsButtonMini onClick={() => setStep(getBackStep())}>
              Voltar
            </FsButtonMini>
          </div>
        ) : (
          <div>
            <FsButtonMini
              disabled={!!customerData?.id}
              onClick={() => push('/')}
            >
              Voltar ao site
            </FsButtonMini>
          </div>
        )}
      </S.Header>
      {!!progress && <FsProgressLine value={progress} />}
      <S.Grid>
        <S.ContainerForm>
          <S.Content>{children}</S.Content>
        </S.ContainerForm>
        <S.Terms>
          {showTerms && (
            <FsParagraph>
              Ao avançar, você confirma estar ciente e aceitar todos os nossos{' '}
              {''}
              <FsLink href="/termos-de-uso/" target="_blank">
                Termos de uso
              </FsLink>{' '}
              e nossa {''}
              <FsLink href="/politicas-de-privacidade/" target="_blank">
                {''}
                Política de Privacidade
              </FsLink>
            </FsParagraph>
          )}
          <S.Support>
            <FsParagraph>Precisa de ajuda?</FsParagraph>
            <FsLink
              href="https://api.whatsapp.com/send?phone=551141183816"
              target="_blank"
            >
              Suporte por Whatsapp
            </FsLink>
          </S.Support>
        </S.Terms>
      </S.Grid>
    </S.Container>
  );
};
